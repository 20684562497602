import en from './en.json'
import de from './de.json'
import fr from './fr.json'
import es from './es.json'
import hu from './hu.json'

let windowLang = document.documentElement.lang.split("-")[0]

let host = window.location.host || window.location.hostname
if (host === "www.launch-2022.es" || host === "launch-2022.es") {
    windowLang = "es";
}

// TODO: make more dynamic
export default windowLang === 'fr' ? fr : (windowLang === 'de' ? de : (windowLang === 'es' ? es : (windowLang === 'hu' ? hu : en)))
