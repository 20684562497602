/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger';
import dayjs from 'dayjs';

const App = types
	.model('App', {
		is_loading: types.optional(types.boolean, true),
		error: types.optional(types.string, ""),
		show_countdown: types.optional(types.boolean, false),
		header_title: types.maybeNull(types.string),
		redirect_link: types.maybeNull(types.string),
		button_text: types.maybeNull(types.string),
		complete_title: types.maybeNull(types.string),
		countdown_complete: types.optional(types.boolean, false),
		auto_redirect: types.optional(types.boolean, false),
		debug_enabled: types.optional(types.boolean, false),
		is_past: types.optional(types.boolean, false)
	})
	.actions(self => ({

		hydrate: flow(function* (start_date_time, element) {
			if(element?.getAttribute("data-enable-debug") !== null){
				self.debug_enabled = true
			}
			Logger.log("App:hydrate", start_date_time)
			self.is_loading = true
			
			// Optionally check if we are showing this module just for logged-in users
			if(element?.getAttribute("data-active-user-only") !== null){
				const is_active = document.querySelector('.user-active');
				Logger.log("App:hydrate:user_is_active_class", is_active)
				if(!is_active){
					// Check if we are inactive first
					const is_inactive = document.querySelector('.user-inactive')
					if(is_inactive){
						setTimeout(() => {
							App.set_error_message("No user is active yet... Waiting")
						}, 500)
					}
					else{
						setTimeout(() => {
							App.set_error_message("No user is active")
						}, 5000)
					}
					return
				}
			}
			
			// Check if we have a date prop and if it's in range
			if(dayjs().isBefore(dayjs(start_date_time))){
				// Let's check we have text also, doesn't matter really
				yield App.load_and_set_attributes(element)
				Logger.log("App:hydrate:set_text", `${self.header_title}, ${self.button_text}, ${self.redirect_link}, ${self.complete_title}`, self.auto_redirect)
				self.show_countdown = true
			}
			else if(dayjs().isAfter(dayjs(start_date_time))){
				yield App.load_and_set_attributes(element)
				Logger.log("App:hydrate:set_text:AFTER", `${self.header_title}, ${self.button_text}, ${self.redirect_link}, ${self.complete_title}`, self.auto_redirect)
				self.is_past = true
				if(self.auto_redirect && self.redirect_link){
					window.location.href = self.redirect_link
				}
				self.show_countdown = true
				self.countdown_complete = true
			}
			self.cleanup_dom(element)
			self.is_loading = false
		}),

		set_is_loading: flow(function* (loading) {
			Logger.log("App:set_is_loading", loading)
			self.is_loading = loading
		}),
		
		load_and_set_attributes: flow(function* (element) {
			Logger.log("App:load_and_set_attributes", element)
			self.header_title = element?.getAttribute("data-header-title")
			self.button_text = element?.getAttribute("data-button-text")
			self.redirect_link = element?.getAttribute("data-link")
			self.complete_title = element?.getAttribute("data-complete-title")
			self.auto_redirect = element?.getAttribute("data-auto-redirect") !== null
		}),

		set_error_message: flow(function* (message) {
			Logger.log("App:set_error_message", message)
			self.error = message
			self.is_loading = false
			setTimeout(() => {
				App.hydrate()
			}, 8000)
		}),
		
		set_countdown_complete: flow(function* () {
			Logger.log("App:set_countdown_complete")
			// Let's check if we need to redirect
			if(self.auto_redirect && self.redirect_link){
				window.location.href = self.redirect_link
			}
			self.countdown_complete = true
		}),
		
		cleanup_dom: flow(function* (element) {
			Logger.log("App:cleanup_dom", element)
			if(!App.debug_enabled){
				// Let's clean up the DOM a little bit.
				element.removeAttribute('data-start-date-time')
				element.removeAttribute('data-complete-title')
				element.removeAttribute('data-header-title')
				element.removeAttribute('data-button-text')
				element.removeAttribute('data-link')
				element.removeAttribute('data-active-user-only')
			}
			element.setAttribute('data-module-did-load', "true")
		}),

	}))
	.create();

export default App;
