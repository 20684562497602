import 'react-app-polyfill/ie11';
import './utils/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './modules/App'

const countdown_element = document.querySelector('[data-react-clip="countdown"]')

if (countdown_element) {
  const start_date_time = countdown_element.getAttribute('data-start-date-time')
  // If no start time, don't show the module
  if(start_date_time){
    ReactDOM.render(
      <React.StrictMode>
        <App element={countdown_element} start_date_time={start_date_time} />
      </React.StrictMode>,
      countdown_element
    );
  }
}
