import React from 'react';
import { observer } from 'mobx-react';
import App from '../stores/App'
import Countdown, { zeroPad } from 'react-countdown';
import Locale  from './../locales/locale'
import dayjs from 'dayjs';

// This is the main starting point, where it all begins.
const AppModule = observer(
	class AppModule extends React.Component {

		componentDidMount = async () => {
			App.hydrate(this.props.start_date_time, this.props.element)
		}
		
		completed_container = () => {
			return(
				<div className="clip__countdown-done">
					{App.complete_title ? <h2>{App.complete_title}</h2> : null}
					{
						App.redirect_link ?
							<a
								className="clip__countdown-button"
								href={App.redirect_link}
							>
							{App.button_text ? App.button_text : Locale.BUTTON_TEXT}
							</a>
						: null
					}
				</div>
			)
		}
		
		countdown_renderer = ({ days, hours, minutes, seconds, completed }) => {
			const element_classname = "clip__countdown-count-element"
			const separator_classname = "clip__countdown-count-separator"
			if (completed) {
				return this.completed_container()
			} else {
				return(
					<div className="clip__countdown-count">
						<span className={element_classname}>
							{zeroPad(days)}
							<span>{days !== 1 ? Locale.DAYS : Locale.DAY}</span>
						</span>
						<span className={separator_classname}>:</span>
						<span className={element_classname}>
							{zeroPad(hours)}
							<span>{hours !== 1 ? Locale.HOURS : Locale.HOUR}</span>
						</span>
						<span className={separator_classname}>:</span>
						<span className={element_classname}>
							{zeroPad(minutes)}
							<span>{minutes !== 1 ? Locale.MINUTES : Locale.MINUTE}</span>
						</span>
						<span className={separator_classname}>:</span>
						<span className={element_classname}>
							<div>{zeroPad(seconds)}</div>
							<span>{seconds !== 1 ? Locale.SECONDS : Locale.SECOND}</span>
						</span>
					</div>
				);
			}
		};

		render() {
			if(!App.is_loading && App.show_countdown){
				return(
					<div className="clip clip__countdown-container">
						{
							!App.countdown_complete && App.header_title && !App.is_past ? <h2 className="clip__countdown-title">{App.header_title}</h2> : null
						}
						<Countdown
							date={dayjs(this.props.start_date_time)}
							renderer={this.countdown_renderer}
							onComplete={App.set_countdown_complete}
						/>
					</div>
				)
			}
			return null
		}
	}
);
export default AppModule