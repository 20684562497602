/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import App from './App';

const Logger = types
	.model('Logger', {})
	.actions(() => ({
		log: flow(function* (arg_one, arg_two, arg_three, arg_four) {
			if (App.debug_enabled) {
				if (arg_four !== undefined) {
					console.log("COUNTDOWN LOG:", arg_one, arg_two, arg_three, arg_four)
				}
				else if (arg_three !== undefined) {
					console.log("COUNTDOWN LOG:", arg_one, arg_two, arg_three)
				}
				else if (arg_two !== undefined) {
					console.log("COUNTDOWN LOG:", arg_one, arg_two)
				}
				else {
					console.log("COUNTDOWN LOG:", arg_one)
				}
			}
		}),
		warn: flow(function* (arg_one, arg_two, arg_three, arg_four) {
			if (App.debug_enabled) {
				if (arg_four !== undefined) {
					console.warn("COUNTDOWN WARN:", arg_one, arg_two, arg_three, arg_four)
				}
				else if (arg_three !== undefined) {
					console.warn("COUNTDOWN WARN:", arg_one, arg_two, arg_three)
				}
				else if (arg_two !== undefined) {
					console.warn("COUNTDOWN WARN:", arg_one, arg_two)
				}
				else {
					console.warn("COUNTDOWN WARN:", arg_one)
				}
			}
		}),
	}))
	.create();

export default Logger;
